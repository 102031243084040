import React, { useEffect, useId, useRef, useState } from 'react';
import { OTSession, OTPublisher, OTSubscriber, OTStreams } from 'opentok-react';
import { Alert, Col, Row, Input, Typography, Tooltip } from 'antd';
import SendForm from './Components/SendForm';
import './Stream.css'
import io from "socket.io-client"
import { Button } from 'react-bootstrap';

const ShowStatus = ({ mesage }) => {
  console.log(mesage)
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h4>{mesage}</h4>
    </div>
  )
}
const apiKey = '47706901';
function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const UserType = searchParams.get("type");
  const user_name = searchParams.get("user_name");
  const userId = searchParams.get("user_id")
  const [roomId, setRomId] = useState(searchParams.get("room_id"))

  const [sessionId, setSessionId] = useState(searchParams.get("sessionId"));
  const [token, seToken] = useState(searchParams.get("token"));
  const [isJoin, setJoin] = useState(false)
  const [Permission, setPermissionStatus] = useState('');
  const [streamStatus, setStreamStatus] = useState('')
  // const url = 'http://localhost:3001/'
  const url = 'https://albahrgroup.com/'
  // const url = 'https://shahna.custom.projects-delivery.com/'
  const socket = io.connect(url)

  const [message, setMessage] = useState('');
  const [myId, setMyId] = useState('')
  const [users, setUsers] = useState([])
  const [allmessage, setallmessage] = useState([])
  const [Loading, setLoading] = useState(false)
  const [isStream, setIsStream] = useState(false)
  const containerRef = useRef(null);
  useEffect(() => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [allmessage]);

  useEffect(() => {
    // setLoading(true)
    socket.on('me', id => {
      setMyId(id)
      if (UserType == 'live') {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          alert("getUserMedia() is not supported.");
          setLoading(false)
          return;
        }
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
          .then(() => {
            setPermissionStatus("granted")
            fetch(url + "getSessionId")
              .then(response => response.json())
              .then(data => {
                console.log(id)
                console.log(data.sessionId)
                console.log(data.token)
                setRomId(id)
                setSessionId(data.sessionId)
                seToken(data.token)
                CreateRoom(data.sessionId, data.token, id)
              })
              .catch(error => {

                console.error(error)
              });
          })
          .catch(() => {

            setPermissionStatus("denied")
          });
      }
      else if (UserType == 'join') {
        JoinRoom(id)
      }
    })
    // console.log(typeof (users[0].id))
    socket.on("createRoom", data => {
      if (data.status) {
        setIsStream(true)
        fetch('https://custom.projects-delivery.com/shahana/api/create-streaming', {
          // fetch('https://fansseefeet.com/api/create-streaming', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_id: data.data.user_id,
            room_id: data.data.room_id,
            session_id: data.data.session_id,
            token: data.data.token
          })
        })
          .then(response => {
            console.log(response)
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            console.log(data);
            // handle successful response
          })
          .catch(error => {
            console.error('There was an error!', error);
            // handle error
          });

      }
    })
    socket.on("joinRome", data => {
      if (data.status) {
        setJoin(true)
        setLoading(false)
        // alert(data.message)
      }
      else {
        setLoading(false)
        setStreamStatus('error')
        alert(data.message)
      }
    })
    socket.on("newUser", data => {
      setUsers(prev => [...prev, data])
    })
    socket.on("message", data => {
      setallmessage(prev => [...prev, data])
    })
    socket.on("userDisconnected", id => {
      setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
    })
    socket.on("removeUser", data => {
      alert(data.message)
      setStreamStatus("removed")
      setJoin(false)
    })
    socket.on("status", data => {
      setLoading(false)
      alert(data.message)
      setStreamStatus("end")
    })
  }, [UserType])
  const CreateRoom = (session, token, id) => {
    let data = {
      sessionId: session,
      token: token,
      roomId: id,
      username: user_name,
      userId: userId
    }
    socket.emit('createRoom', data)
  }
  const JoinRoom = (id) => {
    if (!roomId || !user_name || !id || !sessionId) {
      return alert("Invalid url")
    }
    let data = {
      roomId: roomId,
      username: user_name,
      myId: id,
      sessionId: sessionId
    }
    socket.emit('joinRome', data)
  }

  const handleSend = (e) => {
    // console.log({ roomId: roomId, message: message, username: user_name, myId: myId })
    e.preventDefault()
    if (!message) {
      return null
    }
    setallmessage(prev => [...prev, { username: user_name, message: message, id: myId }])
    socket.emit("message", { roomId: roomId, message: message, username: user_name, myId: myId })
    setMessage('')
  }
  const HandleRemove = (item) => {
    socket.emit("removeUser", { userID: item.id, roomId: roomId })
    setUsers(users.filter((i) => i.id !== item.id));
  }
  let videoComponent;
  if (UserType === 'join' && isJoin) {
    videoComponent =
      <OTStreams>
        <OTSubscriber />
      </OTStreams>;
  } else if (UserType === 'live' && Permission == 'granted') {
    videoComponent = <OTPublisher />;
  }
  if (streamStatus == 'removed') {
    return (
      <ShowStatus mesage={"Sorry!! you are removed by admin."} />
    )
  } else if (streamStatus == 'end') {
    return (
      <ShowStatus mesage={"Sorry!! admin end this stream"} />
    )
  } else if (streamStatus == 'error') {
    return (
      <ShowStatus mesage={"404! something went wrong."} />
    )
  }
  const HandleEndStream = () => {
    window.location.replace('https://fansseefeet.com');
  }
  return (
    <div className='videoStreaming'>
      {
        !Loading ?
          <div className='container'>
            <Row>
              <Col
                className='videoCall'
                span={16}
                xs={{
                  span: 24,
                }}
                sm={{
                  span: 24,
                }}
                md={{
                  span: 8,
                }}
                lg={{
                  span: 16,
                }}
              >
                <div className='videoWrapper'>
                  {UserType == 'live' && sessionId && token && isStream && (
                    <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
                      <div style={{ width: '100%', background: 'red' }}>
                        {videoComponent}
                      </div>

                    </OTSession>
                  )}
                  {UserType == 'join' && sessionId && token && isJoin && (
                    <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
                      <div style={{ width: '100%', background: 'red' }}>
                        {videoComponent}
                      </div>
                    </OTSession>
                  )}
                  <div className='chatShow' ref={containerRef}>
                    {allmessage.map((item, index) => {
                      return (
                        <div className='chatInner' key={index}>
                          <>
                            <div className='memberName'>
                              {item.username}
                            </div>
                            <div className='memberMessage'>
                              {item.message}
                            </div>
                          </>
                        </div>
                      )
                    })}
                  </div>
                  <div className='chatForm' style={{ marginTop: 20 }}>
                    <SendForm
                      onChange={(e) => setMessage(e.target.value)}
                      message={message}
                      handleClick={(e) => handleSend(e)}
                    />

                  </div>
                </div>

              </Col>
              {UserType == "live" &&
                <Col
                  className='chatCol'
                  span={8}
                  xs={{
                    span: 24,
                  }}
                  sm={{
                    span: 24,
                  }}
                  md={{
                    span: 8,
                  }}
                  lg={{
                    span: 8,
                  }}
                >
                  <div className='chatContainer'>
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">Members</h4>
                      <Button variant="danger" type="submit" style={{ marginRight: 20 }}
                        onClick={() => HandleEndStream()}
                      >
                        End Stream
                      </Button>
                    </div>

                    {users.map((val, i) => {
                      return (
                        <div key={i} style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
                          <button style={{ border: 0 }} onClick={() => HandleRemove(val)}>
                            <div style={{ width: 20, height: 20, backgroundColor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}>X</div>
                          </button>

                          <a style={{ marginLeft: 20 }}>{val.username}</a>
                        </div>
                      )
                    })}
                  </div>
                </Col>
              }
            </Row>
            <Row>
              <Col
                span={24}
                xs={{
                  span: 24,
                }}
                sm={{
                  span: 24,
                }}
                md={{
                  span: 24,
                }}
                lg={{
                  span: 24,
                }}
              >
              </Col>
            </Row>
          </div>
          :
          <ShowStatus mesage={"Loading"} />
      }

    </div>





    // <div>
    //   {!UserType &&
    //     <div style={{
    //       display: 'flex',
    //       flexDirection: 'row',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //       height: '100vh'
    //     }}>
    //       <button style={{
    //         backgroundColor: '#eee',
    //         padding: '10px 20px',
    //         marginRight: '10px'
    //       }} onClick={() => GoLive()}>Go Live</button>
    //       <button style={{
    //         backgroundColor: '#fefe',
    //         padding: '10px 20px'
    //       }} onClick={() => JoinStream()}>Join Streem</button>
    //     </div>
    //   }

    //   {/* Stream start here */}


    //   <div style={{
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     height: '100vh'
    //   }}>

    //     {UserType && sessionId && token && (
    //      <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
    //         {videoComponent}
    //       </OTSession>
    //     )} 
    //     {UserType == 'join' &&
    //       <>
    //         <input placeholder='enter session Id' onChange={(e) => setSessionId(e.target.value)} />
    //         <input placeholder='enter token' onChange={(e) => seToken(e.target.value)} />
    //         <button onClick={() => setJoin(true)}>Join</button>
    //       </>
    //     }
    //   </div>
    //   <div style={{ width: '100%', padding: '10px', display: 'flex', maxWidth: '100%', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
    //     <div style={{ width: '50%', justifyContent: 'center', backgroundColor: 'gray' }}>
    //       <a>SessionId:  {sessionId}</a>
    //       <a style={{ wordWrap: 'break-word' }}>Token:  {token}</a>
    //     </div>
    //   </div>

    // </div>
  );
}

export default App;



