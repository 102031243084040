import React from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';

function SendForm({ onChange, handleClick, message }) {
    return (
        <>
            <Form onSubmit={handleClick}>
                <Row className="align-items-center">
                    <Form.Group className='formInput'>
                        <Form.Control type="text" placeholder="Enter message..."
                            onChange={onChange}
                            value={message}
                        />
                    </Form.Group>
                    <Button variant="primary" type='submit'>
                        Send
                    </Button>
                    {/* <Col xs={9}>

                </Col>
                <Col>

                </Col> */}
                </Row>
            </Form>

        </>
    )
}

export default SendForm